<header class="header">
    <div class="container">
        <nav class="navbar navbar-expand-lg">
            <div class="container-fluid ps-0">
                <a class="navbar-brand" routerLink="/portugalmls">
                    <img src="assets/img/portugal-mls-logo-removebg-preview.png" width="200px" class="img-fluid" alt="">
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse d-lg-flex justify-content-lg-end" id="navbarSupportedContent">
                    <ul class="navbar-nav mb-2 mb-lg-0">
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" routerLink="/portugalmls"
                                routerLinkActive="active">{{'Home' | translate }}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/about"
                                routerLinkActive="active">{{'About' | translate }}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/FAQs" routerLinkActive="active">{{'FAQs'
                                | translate }}</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#">
                                <img src="assets/img/langugae-icons.svg" width="30px" class="img-fluid" alt="">
                            </a>
                        </li> -->
                        <li class="nav-item">
                            <a class="nav-link">
                                <div class="dropdown lang-drpDwn">
                                    <button class="btn btn-secondary dropdown-toggle p-0" id="dropdownMenuLink"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <img [src]="currentLanguage.icon" class="flag-img" [alt]="currentLanguage.name"
                                            height="22" width="29">
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <li *ngFor="let language of languages">
                                            <a class="dropdown-item" (click)="changeLanguage(language)">
                                                <img [src]="language.icon" height="18" width="21">
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </a>
                        </li>
                    </ul>
                    <div class="d-flex align-items-center">
                        <a class="btn explore-btn" type="submit" routerLink="/login">{{ 'Login' | translate}}</a>
                    </div>
                    <!-- <div class="d-flex align-items-center" *ngIf="!isForLoginPage">
                        <a class="btn explore-btn" type="submit" routerLink="/login">{{ 'Login' | translate}}</a>
                    </div> -->
                </div>
            </div>
        </nav>
    </div>
</header>