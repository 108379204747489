import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { jwtDecode } from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private userDetails = new BehaviorSubject<{ userId: number; roleId: number} | null>(null)
  userDetail$ = this.userDetails.asObservable()
  private userIds = new BehaviorSubject<number[]>([])
  userIds$ = this.userIds.asObservable()

  constructor() {
    const token = localStorage.getItem('LoggedUserToken')
    if(token){
      const decodedTkn: any = jwtDecode(token)
      this.userDetails.next({ userId: Number(decodedTkn.UserId), roleId: Number(decodedTkn.RoleId) })
    }
  }

  setUserDetails(userId: number, roleId: number) {
    this.userDetails.next({ userId, roleId });
  }

  getUserDetails(): {userId: number; roleId: number} | null {
    return this.userDetails.getValue()
  }

  setUserIds(userId: number[]){
    this.userIds.next(userId)
  }

  resetDetails(){
    this.userDetails.next(null)
  }

}
