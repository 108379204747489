import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { LoginService } from 'src/app/services/Login/login.service';
import { CommonService } from 'src/shared/common.service';

@Component({
  selector: 'app-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.css']
})
export class SignupFormComponent implements OnInit {
  signUpForm!:FormGroup
  selectedTimePreference:string="anytime"
  arePreferencesDisabled:boolean=true

  @ViewChild('confirmationModal', { static: false }) confirmationModal? : ModalDirective;

  constructor(
    private fb:FormBuilder,
    public commonService:CommonService,
    private router: Router,
    private loginService:LoginService,
  ){}

  ngOnInit(): void {
    this.initSignupForm()
    this.signUpForm.get('preferences').get([0]).get('preferenceDate').disable();
    this.signUpForm.get('preferences').get([1]).get('preferenceDate').disable();
  }

  initSignupForm(){
    this.signUpForm=this.fb.group({
      name: ["", Validators.required],
      phone: ["", Validators.required],
      email: ["", Validators.required],
      companyLicense: ["", Validators.required],
      companyName: ["", Validators.required],
      companyPhone: ["", Validators.required],
      preferences: this.fb.array([
        this.fb.group({
        preferenceDate: [""],
        startTime: [""],
        endTime: [""]
      }),
      this.fb.group({
        preferenceDate: [""],
        startTime: [""],
        endTime: [""]
      })]),
      areTermsAndConditionsAgreed:[false, [Validators.requiredTrue]]
    })
  }
  setSelectedTimePreference(event:Event){
    const input = event.target as HTMLInputElement;
    this.selectedTimePreference = input.value;
    this.arePreferencesDisabled = this.selectedTimePreference==='anytime'
    if(this.arePreferencesDisabled){
      this.signUpForm.get('preferences').get([0]).get('preferenceDate').disable();
      this.signUpForm.get('preferences').get([1]).get('preferenceDate').disable();
    } else{
      this.signUpForm.get('preferences').get([0]).get('preferenceDate').enable();
      this.signUpForm.get('preferences').get([1]).get('preferenceDate').enable();
    }
  }

  formatPhone(event: any, field: string): void {
    let enteredValue = event.target.value;
    const countryCode = '(+351)';
    if (enteredValue === '' || enteredValue === countryCode) {
      this.signUpForm.get(field).setValue('');
    } else if (!enteredValue.startsWith(countryCode)) {
      this.signUpForm.get(field).setValue(countryCode + enteredValue);
    } else {
      this.signUpForm.get(field).setValue(enteredValue);
    }
  }

  signup(){
    if (this.signUpForm.invalid){
      this.signUpForm.markAllAsTouched()
      return 
    }

    const requestBody=this.signUpForm.value
    if (this.selectedTimePreference==='anytime'){
      requestBody.preferences=[]
    } 
    else {
      if (requestBody.preferences.some((preference: { [x: string]: any; })=>Object.keys(preference).some(field=>!preference[field]))){
        return
      }
    }

    delete requestBody.areTermsAndConditionsAgreed
    requestBody.isAnyTime=this.selectedTimePreference==='anytime'

    this.loginService.signup(requestBody).subscribe(
      (response: any) => {
        console.log('Response:', response);
        if (response) {
          this.confirmationModal.show()
        }
        this.initSignupForm(); // to reset form (??)
      },
      (error: any) => {
        console.error('Error:', error);
      }
    ); 
    //this.router.navigate(['/login'])
  }

  
}
