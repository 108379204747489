import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AssetStatus, BusinessType } from './service-proxies/service-proxies';
import { ActivatedRoute, Router } from '@angular/router';
import { Moment } from 'moment';
import { formatDate } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { RoleService } from 'src/app/services/RoleManagement/role.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private statusSubject = new BehaviorSubject<AssetStatus>(AssetStatus.None);
  private businessTypeSubject = new BehaviorSubject<BusinessType>(
    BusinessType.None
  );
  private assetIdSubject = new BehaviorSubject<number>(0);
  private propertyTypeSubject = new BehaviorSubject<string>('');
  private subPropertyTypeSubject = new BehaviorSubject<string>('');
  private assetTitle = new BehaviorSubject<string>('');
  private latitude = new BehaviorSubject<number>(0);
  private longitude = new BehaviorSubject<number>(0);

  private isLeaseRentalStepVisible = new BehaviorSubject<boolean>(true);
  private isCommercialIndustrialStepVisible = new BehaviorSubject<boolean>(
    true
  );
  private isAssociationCondoStepVisible = new BehaviorSubject<boolean>(true);
  private isRoomsStepVisible = new BehaviorSubject<boolean>(true);
  private isStructuralStepVisible = new BehaviorSubject<boolean>(true);
  private isUnitsStepVisible = new BehaviorSubject<boolean>(true);
  private isSchoolStepVisible = new BehaviorSubject<boolean>(true);
  private isUtilitiesStepVisible = new BehaviorSubject<boolean>(true);
  private ownersIdSubject = new BehaviorSubject<number>(undefined)
  private isPublishedSubject = new BehaviorSubject<boolean>(false)
  private colistingAgentIdSubject = new BehaviorSubject<number>(undefined)
  private sellingAgentIdSubject = new BehaviorSubject<number>(undefined)
  private cosellingAgentIdSubject = new BehaviorSubject<number>(undefined)
  private isLockedSubject = new BehaviorSubject<boolean>(false)
  loggedInUserRoleId: number
  private isNextListing = new BehaviorSubject<boolean>(false)
  private isPreviousListing = new BehaviorSubject<boolean>(false)
  private isNextBtnVisible = new BehaviorSubject<boolean>(true)
  private isPreviousBtnVisible = new BehaviorSubject<boolean>(true)
  private currentListing = new BehaviorSubject<number>(0)
  private totalListings = new BehaviorSubject<number>(0)
  private isAddListingPage = new BehaviorSubject<boolean>(false)

  constructor(private route: ActivatedRoute, private router: Router, private roleService: RoleService) {
    this.roleService.userDetail$.subscribe(detail => {
      this.loggedInUserRoleId = detail.roleId
    })

  }

  getLabelFromOptions(options: any[], value: any): any {
    const selectedOption = options.find((option) => option.value === value);
    return selectedOption ? selectedOption.label : value;
  }

  isControlInvalid(formGroup: any, controlName: string) {
    const control = formGroup?.get(controlName);
    return control?.invalid && (control?.touched || control?.dirty);
  }

  setStatus(status: AssetStatus) {
    this.statusSubject.next(status);
  }

  getStatus(): Observable<AssetStatus> {
    return this.statusSubject.asObservable();
  }

  setBusinessType(businessType: BusinessType) {
    this.businessTypeSubject.next(businessType);
  }

  getBusinessType(): Observable<BusinessType> {
    return this.businessTypeSubject.asObservable();
  }

  setAssetId(id: number) {
    this.assetIdSubject.next(id);
  }

  getAssetId() {
    return this.assetIdSubject.asObservable();
  }

  // set property type
  setPropertyType(propertyType: string) {
    this.propertyTypeSubject.next(propertyType);
  }
  // get property type
  getPropertyType() {
    return this.propertyTypeSubject.asObservable();
  }

  // set property type
  setSubPropertyType(subpropertyType: string) {
    this.subPropertyTypeSubject.next(subpropertyType);
  }
  // get property type
  getSubPropertyType() {
    return this.subPropertyTypeSubject.asObservable();
  }

  // get asset title
  getAssetTitle() {
    return this.assetTitle.asObservable();
  }
  // set asset title
  setAssetTitle(title: string) {
    this.assetTitle.next(title);
  }

  getLatitude() {
    return this.latitude.asObservable();
  }

  setLatitude(lat: number) {
    this.latitude.next(lat);
  }

  getLongitude() {
    return this.longitude.asObservable();
  }

  setLongitude(lng: number) {
    this.longitude.next(lng);
  }

  setOwnerId(id: number){
    this.ownersIdSubject.next(id)
  }

  getOwnerId(){
    return this.ownersIdSubject.asObservable()
  }

  setIsPublish(isPub: boolean){
    this.isPublishedSubject.next(isPub)
  }
  getIsPublish(){
    return this.isPublishedSubject.asObservable()
  }

  setColistingAgent(id: number){
    this.colistingAgentIdSubject.next(id)
  }
  getColistingAgent(){
    return this.colistingAgentIdSubject.asObservable()
  }

  setSellingAgent(id: number){
    this.sellingAgentIdSubject.next(id)
  }
  getSellingAgent(){
    return this.sellingAgentIdSubject.asObservable()
  }

  setCosellingAgent(id: number){
    this.cosellingAgentIdSubject.next(id)
  }
  getCosellingAgent(){
    return this.cosellingAgentIdSubject.asObservable()
  }

  setIsLocked(isLock: boolean){
    this.isLockedSubject.next(isLock)
  }
  getIsLocked(){
    return this.isLockedSubject.asObservable()
  }

  // to auto adjust textarea input
  adjustTextareaHeight(textarea: HTMLTextAreaElement) {
    textarea.style.overflow = 'hidden';
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  // add euro sign to input
  formatInput(
    event: any,
    formGroup: any,
    field: any,
    isSaveFormCall: boolean = false,
    addMeterSign: boolean = false,
    addMeterSquareSign: boolean = false
  ) {
    let input: string;
    let formattedInput: any;
    if (isSaveFormCall) {
      input = event?.toString();
    } else {
      input = event?.target.value;
    }
    if (addMeterSign) {
      formattedInput = this.formatMeasurementInput(input);
    } else if (addMeterSquareSign) {
      formattedInput = this.addMeterSquare(input);
    } else {
      formattedInput = this.formatCurrencyInput(input);
    }
    if (formGroup?.length > 0) {
      formGroup?.forEach((form: any) => {
        form.get(field).patchValue(formattedInput);
      });
    } else {
      formGroup.get(field).patchValue(formattedInput);
    }
  }

  formatCurrencyInput(input: string): string {
    const numericValue = parseFloat(input?.replace(/[^0-9.-]+/g, ''));

    if (!isNaN(numericValue)) {
      return `€${numericValue?.toLocaleString('en-US')}`;
    } else {
      return '';
    }
  }

  formatMeasurementInput(input: string): string {
    const numericValue = parseFloat(input?.replace(/[^0-9.-]+/g, ''));

    if (!isNaN(numericValue)) {
      return `${numericValue?.toLocaleString('en-US')} m`;
    } else {
      return '';
    }
  }

  addMeterSquare(input: string): string {
    const numericValue = parseFloat(input?.replace(/[^0-9.-]+/g, ''));

    if (!isNaN(numericValue)) {
      return `${numericValue?.toLocaleString('en-US')} ㎡`;
    } else {
      return '';
    }
  }

  // remove formatting
  removePriceFormatting(formGroupField: any) {
    const value = formGroupField?.value;
    if (typeof value === 'string' && value !== '') {
      const numericValue = this.removeFormatting(value.toString());
      formGroupField.patchValue(numericValue);
    }
  }

  removeFormatting(value: string): any {
    const numericValue = parseFloat(
      value.replace(/[^0-9.-]+/g, '').replace(',', '')
    );
    return isNaN(numericValue) ? null : numericValue;
  }

  getQueryParams() {
    let assetId!: string;
    this.route.queryParams.subscribe((params) => {
      assetId = params['assetId'];
    });

    return parseFloat(assetId);
  }

  isLeaseRentalStepVisible$ = this.isLeaseRentalStepVisible.asObservable();
  isCommercialIndustrialStepVisible$ =
    this.isCommercialIndustrialStepVisible.asObservable();
  isAssociationCondoStepVisible$ =
    this.isAssociationCondoStepVisible.asObservable();
  isRoomsStepVisible$ = this.isRoomsStepVisible.asObservable();
  isStructuralStepVisible$ = this.isStructuralStepVisible.asObservable();
  isUnitsStepVisible$ = this.isUnitsStepVisible.asObservable();
  isSchoolStepVisible$ = this.isSchoolStepVisible.asObservable();
  isUtilitiesStepVisible$ = this.isUtilitiesStepVisible.asObservable();

  setLeaseRentalStepVisibility(visible: boolean) {
    this.isLeaseRentalStepVisible.next(visible);
  }

  setCommercialIndustrialStepVisibility(visible: boolean) {
    this.isCommercialIndustrialStepVisible.next(visible);
  }

  setAssociationCondoStepVisibility(visible: boolean) {
    this.isAssociationCondoStepVisible.next(visible);
  }

  setRoomsStepVisibility(visible: boolean) {
    this.isRoomsStepVisible.next(visible);
  }

  setStructuralStepVisibility(visible: boolean) {
    this.isStructuralStepVisible.next(visible);
  }

  setUnitsStepVisibility(visible: boolean) {
    this.isUnitsStepVisible.next(visible);
  }

  setSchoolStepVisibility(visible: boolean) {
    this.isSchoolStepVisible.next(visible);
  }

  setUtilitiesStepVisibility(visible: boolean) {
    this.isUtilitiesStepVisible.next(visible);
  }

  convertMomentToDate(momentDate: Moment): Date {
    if (!momentDate) {
      return undefined;
    }
    return new Date(
      momentDate.toDate().getTime() -
        momentDate.toDate().getTimezoneOffset() * 60000
    );
  }

  formateSavedDate(momentDate: Date) {
    if(momentDate == null) return null
    return new Date(formatDate(momentDate, 'yyyy-MM-dd', 'en-US'));
  }

  setQueryParameters(mode: string = "", id: any, path: string = "") {
    // Set query parameters
    const queryParams = { mode: mode, assetId: id };

    // Navigate with the new query parameters
    this.router.navigate([path], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: "merge", // Use 'merge' to keep existing query parameters
    });
  }

  formatDate(dateString: string): string {
    if(!dateString) return ''
    const dateParts = dateString.split('T')[0].split('-'); // Split the date string and extract year, month, and day
    const day = dateParts[2];
    const month = dateParts[1];
    const year = dateParts[0];
    return `${day}-${month}-${year}`;
  }

  getDaysDifference(endDate: string) {
    if(!endDate) return ''
    const today = new Date();
    const activationEndDate = new Date(endDate);
    const timeDifference = today.getTime() - activationEndDate.getTime();
    const dayDifference = Math.round(timeDifference / (1000 * 3600 * 24));
    return `${dayDifference}`;
  }

  disableFormFields(formGroup: FormGroup | any){
    let disabled = false

    this.getStatus().subscribe(status => {
      this.getIsPublish().subscribe(isPub => {
        if((status == AssetStatus.Cancelled || status == AssetStatus.Expired || status == AssetStatus.Closed || status == AssetStatus.Withdrawn) && isPub && (this.loggedInUserRoleId == 4 || this.loggedInUserRoleId == 5 || this.loggedInUserRoleId == 6)){
          if(formGroup) formGroup.disable()
          disabled = true
        }
      })

    })

    return disabled
  }

  setNextListing(isNext: boolean) {
    this.isNextListing.next(isNext);
  }

  getNextListing(): Observable<boolean> {
    return this.isNextListing.asObservable();
  }

  setPreviousListing(isPrevious: boolean) {
    this.isPreviousListing.next(isPrevious);
  }

  getPreviousListing(): Observable<boolean> {
    return this.isPreviousListing.asObservable();
  }

  setPreviousBtnVisibility(isPrevious: boolean) {
    this.isPreviousBtnVisible.next(isPrevious);
  }

  getPreviousBtnVisibility(): Observable<boolean> {
    return this.isPreviousBtnVisible.asObservable();
  }

  setNextBtnVisibility(isNext: boolean) {
    this.isNextBtnVisible.next(isNext);
  }

  getNextBtnVisibility(): Observable<boolean> {
    return this.isNextBtnVisible.asObservable();
  }

  setCurrentListing(num: number) {
    this.currentListing.next(num);
  }

  getCurrentListing(): Observable<number> {
    return this.currentListing.asObservable();
  }

  setTotalListings(num: number) {
    this.totalListings.next(num);
  }

  getTotalListings(): Observable<number> {
    return this.totalListings.asObservable();
  }

  setIsAddListingPage(isAddLis: boolean) {
    this.isAddListingPage.next(isAddLis);
  }

  getIsAddListingPage(): Observable<boolean> {
    return this.isAddListingPage.asObservable();
  }
}
