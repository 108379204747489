import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

interface Language {
  code: string;
  name: string;
  icon: string;
}

@Component({
  selector: 'app-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.css']
})
export class PublicHeaderComponent implements OnInit {
  languages: Language[] = [
    { code: 'en', name: 'USA', icon: 'https://img.freepik.com/free-vector/illustration-usa-flag_53876-18165.jpg' },
    { code: 'pt', name: 'Portugal', icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_Portugal.svg/800px-Flag_of_Portugal.svg.png' }
  ];

  // @Input() isForLoginPage?:boolean=false
  savedLang = localStorage.getItem('lang')
  currentLanguage: Language = this.languages.find(lang => lang.code === this.savedLang) || this.languages[1];

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    if (this.savedLang) {
        this.translate.use(this.currentLanguage.code);
    } else {
      this.translate.setDefaultLang(this.languages[1].code);
    }
  }

  changeLanguage(language: Language): void {
    this.currentLanguage = language;
    this.translate.use(language.code);
    localStorage.setItem('lang', language.code);
    window.location.reload();
  }
}
