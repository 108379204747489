<section class="py-4 py-xxl-5 sign-in-section" style="background-image: url('assets/img/sign-in-bg-img.png')">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-xxl-6 text-center">
                <div class="bg-text">
                    <div class="d-flex flex-column justify-content-center align-items-center">
                        <img src="assets/img/name-mls1.png" alt="Portugal MLS Logo"
                            class="img-fluid mb-4 mls-form-logo">
                    </div>
                    <h2 class="mb-1">{{ 'Readytobepartofthemovement' | translate }}</h2>
                    <p class="mb-lg-4 mb-0">{{ 'JoinPortugalMLStodayandbe' | translate }}</p>
                    <p>{{ 'LetsrevolutionizeRealEstate' | translate }}</p>
                    <div class="d-flex flex-column flex-sm-row mt-3 mt-lg-5 mb-3 mb-lg-0 d-none">
                        <div class="contact-div d-flex align-items-center">
                            <div class="me-3">
                                <img src="assets/img/phone-img.svg" alt="">
                            </div>
                            <div class="d-flex flex-column">
                                <h4 class="mb-0">{{ 'PHONE1' | translate }}</h4>
                                <p class="mb-0">780 901 6356</p>
                            </div>
                        </div>

                        <div class="contact-div d-flex align-items-center ps-sm-5 mt-3 mt-sm-0 ps-0">
                            <div class="me-3">
                                <img src="assets/img/email-img.svg" alt="">
                            </div>
                            <div class="d-flex flex-column">
                                <h4 class="mb-0">{{ 'EMAIL' | translate }}</h4>
                                <p class="mb-0">{{ 'Info@PortugalMLS.com' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-6">
                <div class="form-box">
                    <form [formGroup]="signUpForm" (submit)="signup()">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="did-floating-label-content mb-3">
                                    <input class="did-floating-input" type="text" placeholder=" " formControlName="name" pattern="^[A-Za-z ]+$" [ngClass]="{
                                        'is-invalid': commonService.isControlInvalid(
                                          signUpForm,
                                          'name'
                                        )
                                      }"/>
                                    <label class="did-floating-label">{{ 'Name' | translate }}</label>
                                    <div class="invalid-feedback">
                                    <span *ngIf="signUpForm.get('name').hasError('required')">{{'Thisfieldisrequired' | translate}}</span>
                                    <span *ngIf="signUpForm.get('name').hasError('pattern')">{{'Namemustbeinalphabets' | translate}}</span>
                                </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="did-floating-label-content mb-3">
                                    <input class="did-floating-input" type="email" placeholder=" " formControlName="email" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" [ngClass]="{
                                        'is-invalid': commonService.isControlInvalid(
                                          signUpForm,
                                          'email'
                                        )
                                      }"/>
                                    <label class="did-floating-label">{{ 'Email' | translate }}</label>
                                    <div class="invalid-feedback">
                                    <span *ngIf="signUpForm.get('email').hasError('required')" >{{'Thisfieldisrequired' | translate}}</span>
                                    <span *ngIf="signUpForm.get('email').hasError('pattern')" >{{'Enteravalidemailaddress.' | translate}}</span>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="did-floating-label-content mb-3">
                                    <input 
                                    class="did-floating-input" 
                                    type="text" placeholder=" " 
                                    formControlName="phone" 
                                    pattern="\(\+351\)[0-9]+$" 
                                    maxlength="15"
                                    minlength="15"
                                    (input)="formatPhone($event, 'phone')"
                                    [ngClass]="{
                                        'is-invalid': commonService.isControlInvalid(
                                          signUpForm,
                                          'phone'
                                        )
                                    }"
                                    />
                                    <label class="did-floating-label">{{ 'PhoneNo' | translate }}</label>
                                    <div class="invalid-feedback">
                                    <span *ngIf="signUpForm.get('phone').hasError('required')">{{'Thisfieldisrequired' | translate}}</span>
                                    <span *ngIf="signUpForm.get('phone').hasError('pattern')">{{'Phonemustbeinnumbers' | translate}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="did-floating-label-content mb-3">
                                    <input class="did-floating-input" type="text" placeholder=" " formControlName="companyLicense"  [ngClass]="{
                                        'is-invalid': commonService.isControlInvalid(
                                          signUpForm,
                                          'companyLicense'
                                        )
                                      }"/>
                                    <label class="did-floating-label">{{ 'CompanyLicense' | translate }}</label>
                                    <div *ngIf="
                                    commonService.isControlInvalid(
                                        signUpForm,
                                        'companyLicense'
                                    )
                                    " class="invalid-feedback">
                                    <span>{{'Thisfieldisrequired' | translate}}</span>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="did-floating-label-content mb-3">
                                    <input class="did-floating-input" type="text" placeholder=" " formControlName="companyName" [ngClass]="{
                                        'is-invalid': commonService.isControlInvalid(
                                          signUpForm,
                                          'companyName'
                                        )
                                      }" />
                                    <label class="did-floating-label">{{ 'CompanyName' | translate }}</label>
                                    <div *ngIf="
                                    commonService.isControlInvalid(
                                        signUpForm,
                                        'companyName'
                                    )
                                    " class="invalid-feedback">
                                    <span>{{'Thisfieldisrequired' | translate}}</span>
                                </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="did-floating-label-content mb-3">
                                    <input 
                                    class="did-floating-input" 
                                    type="text" placeholder=" " 
                                    formControlName="companyPhone" 
                                    pattern="\(\+351\)[0-9]+$" 
                                    maxlength="15"
                                    minlength="15"
                                    (input)="formatPhone($event, 'companyPhone')"
                                    [ngClass]="{
                                        'is-invalid': commonService.isControlInvalid(
                                          signUpForm,
                                          'companyPhone'
                                        )
                                    }"
                                    />
                                    <label class="did-floating-label">{{ 'CompanyPhone' | translate }}</label>
                                    <div class="invalid-feedback">
                                    <span *ngIf="signUpForm.get('companyPhone').hasError('required')">{{'Thisfieldisrequired' | translate}}</span>
                                    <span *ngIf="signUpForm.get('companyPhone').hasError('pattern')">{{'Phonemustbeinnumbers' | translate}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mb-3">
                            <label class="form-label time-label">{{ 'BestTimetoContact' | translate }}</label>
                            <div class="mb-3 form-check">
                                <input type="radio" checked name="timePreference" value="anytime" (change)="setSelectedTimePreference($event)" class="form-check-input" id="exampleCheck12">
                                <label class="form-check-label time-label align-items-center d-flex any-time-width"
                                    for="exampleCheck12">
                                    {{ 'Anytime' | translate }}
                                    <i tooltip="{{ 'Selectthisoptiontobecontactedanytimeoftheday' | translate }}"
                                        class="bi bi-info-circle ms-2"></i>
                                </label>
                            </div>
                            <div class="mb-3 form-check">
                                <input type="radio" name="timePreference" value="selected preference" (change)="setSelectedTimePreference($event)" class="form-check-input" id="exampleCheck1">
                                <label class="form-check-label time-label" for="exampleCheck1">
                                    {{ 'SelectyourpreferredDateTime' | translate }}</label>
                            </div>
                        </div>
                        <div formArrayName="preferences" *ngIf="!arePreferencesDisabled">
                            <div class="row mb-3 justify-content-center align-items-center" [formGroupName]="0">
                                <div class="col-lg-4">
                                    <div class="did-floating-label-content date-fields">
                                        <input [disabled]="arePreferencesDisabled" formControlName="preferenceDate" class="did-floating-input" type="text" placeholder=" " bsDatepicker />
                                        <i class="fa-solid fa-calendar-days"></i>
                                        <label class="did-floating-label">{{ 'Preference1' | translate }}</label>
                                    </div>
                                </div>
                                <div class="col-lg-4 time-input text-center">
                                    <label class="form-label time-label">{{ 'StartTime' | translate }}</label>
                                    <timepicker [disabled]="arePreferencesDisabled" [showMeridian]="false" formControlName="startTime" class="adjust-time-picker d-flex justify-content-center align-items-center">
                                    </timepicker>
                                </div>
                                <div class="col-lg-4 time-input text-center">
                                    <label class="form-label time-label">{{ 'EndTime' | translate }}</label>
                                    <timepicker [disabled]="arePreferencesDisabled" [showMeridian]="false" formControlName="endTime" class="adjust-time-picker d-flex justify-content-center align-items-center">
                                    </timepicker>
                                </div>
                            </div>
                            <div class="row mb-3 justify-content-center align-items-center" [formGroupName]="1">
                                <div class="col-lg-4">
                                    <div class="did-floating-label-content date-fields">
                                        <input [disabled]="arePreferencesDisabled" formControlName="preferenceDate" class="did-floating-input" type="text" placeholder=" " bsDatepicker />
                                        <i class="fa-solid fa-calendar-days"></i>
                                        <label class="did-floating-label">{{ 'Preference2' | translate }}</label>
                                    </div>
                                </div>
                                <div class="col-lg-4 time-input text-center">
                                    <label class="form-label time-label">{{ 'StartTime' | translate }}</label>
                                    <timepicker [disabled]="arePreferencesDisabled" [showMeridian]="false" formControlName="startTime" class="adjust-time-picker d-flex justify-content-center align-items-center">
                                    </timepicker>
                                </div>
                                <div class="col-lg-4 time-input text-center">
                                    <label class="form-label time-label">{{ 'EndTime' | translate }}</label>
                                    <timepicker [disabled]="arePreferencesDisabled" [showMeridian]="false" formControlName="endTime" class="adjust-time-picker d-flex justify-content-center align-items-center">
                                    </timepicker>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3 form-check d-xl-flex align-items-xl-center agree-terms-conditions">
                            <input type="checkbox" formControlName="areTermsAndConditionsAgreed" class="form-check-input" id="termsCheck" required>
                            <label class="form-check-label ps-2" for="termsCheck">{{ 'Iagreetothe' | translate }} <a
                                    class="agree-terms-conditions-links"
                                    routerLink="/terms-of-services">{{ 'TermsofServices' | translate
                                    }}</a> {{ 'andhavereadthe' | translate }} <a class="agree-terms-conditions-links"
                                    routerLink="/privacy-policy">{{ 'PrivacyPolicy' | translate
                                    }}</a> {{ 'ofPortugalMLS' | translate }}</label>
                        </div>
                        <button [disabled]="!signUpForm.get('areTermsAndConditionsAgreed').value" type="submit" class="btn explore-btn">{{ 'Submit' | translate }}</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- modal area -->
<div bsModal #confirmationModal="bs-modal"
  class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
    <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
            <div class="modal-body px-lg-4">
                <button type="button" class="btn-close position-absolute end-0 me-3" aria-label="Close" (click)="confirmationModal?.hide()">
                    <span aria-hidden="true" class="visually-hidden">&times;</span>
                </button>
                <div class="d-flex flex-column align-items-center w-100 mb-3">
                    <h1 class="modal-header model-main-heading mb-1 mt-2 text-center fw-bold">{{ 'RegistrationComplete' | translate }}</h1>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p class="mb-1">{{ 'RegisterSentSuccessful' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end of modal area -->