<footer id="footer" class="footer">
    <div class="container footer-top">
        <div class="row">
            <div class="d-flex justify-content-between align-item-center flex-column flex-lg-row">
                <div class="footer-about mb-lg-0 mb-4">
                    <a routerLink="/portugalmls" class="">
                        <img class="footer-logo" src="assets/img/footer-logo1.png" alt="">
                    </a>
                </div>
                <div class="footer-links d-flex align-items-center">
                    <div class="d-flex align-items-center py-2">
                        <img src="assets/img/LocationPoint.svg" alt="">
                        <a class="ms-2">Lisbon, Portugal</a>
                    </div>
                </div>
                <div class="footer-links d-flex align-items-center">
                    <div class="d-flex align-items-center py-2">
                        <img src="assets/img/Email.svg" alt="">
                        <a href="mailto:info@PortugalMLS.com" class="ms-2">{{ 'Info@PortugalMLS.com' }}</a>
                    </div>
                </div>
                <div class="footer-links d-flex align-items-center">
                    <div class="d-flex align-items-center py-2">
                        <img src="assets/img/book_4.svg" alt="">
                        <a routerLink="/terms-of-services" class="ms-2">{{ 'TermsofServices' |
                            translate }}</a>
                    </div>
                </div>
                <div class="footer-links d-flex align-items-center">
                    <div class="d-flex align-items-center py-2">
                        <img src="assets/img/policy.svg" alt="">
                        <a routerLink="/privacy-policy" class="ms-2">{{ 'PrivacyPolicy' | translate
                            }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<section id="footer-bottom" class="footer-bottom">
    <div class="container copyright">
        <div class="row">
            <div class="col-lg-12 d-flex flex-lg-row flex-column justify-content-between align-items-center">
                <p class="right-reserved-text mb-lg-0 mb-3"><span>PortugalMLS.com © {{ 'Allrightreserved' | translate }}
                        2024</span>
                </p>
                <div class="social-links d-flex align-items-center justify-content-center">
                    <a href="https://www.linkedin.com/company/portugalmls/" target="_blank" class="me-2 social-icons">
                        <img src="assets/img/Linkedin.svg" class="img-fluid" alt="">
                    </a>
                    <a href="https://www.facebook.com/PortugalMLS" target="_blank" class="me-2 social-icons">
                        <img src="assets/img/fb-icon.svg" class="img-fluid" alt="">
                    </a>
                    <a href="https://www.instagram.com/portugal_mls" target="_blank" class="me-2 social-icons">
                        <img src="assets/img/insta-icon.svg" class="img-fluid" alt="">
                    </a>
                    <a href="https://www.tiktok.com/@portugal_mls" target="_blank" class="me-2 social-icons">
                        <img src="assets/img/tiktok-icon.svg" class="img-fluid" alt="">
                    </a>
                    <a href="https://x.com/PortugalMLS" target="_blank" class="me-2 social-icons">
                        <img src="assets/img/twitter-icon.svg" class="img-fluid" alt="">
                    </a>
                    <a href="" class="me-2 social-icons d-none">
                        <img src="assets/img/whatsapp-icon.svg" class="img-fluid" alt="">
                    </a>
                    <a href="https://www.youtube.com/@PortugalMLS" target="_blank" class="social-icons">
                        <img src="assets/img/youtube-icon.svg" class="img-fluid" alt="">
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>