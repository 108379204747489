import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/shared/services/http-client.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  

  constructor(private httpService: HttpClientService) { }

  // Validate logged in User
  validateUser = (payload: any) => this.httpService.post('auth', payload);
  refreshToken = (payload: any) => this.httpService.post('auth/Refresh', payload);

  // Reset User Password
  ResetUserPwd = (payload: any) => this.httpService.post('Auth/Reset', payload);

  CreatePaymentIntent = (id: number, payload: any) => this.httpService.post(`user/SetupIntent`, payload);
  // Get logged in User by :Id
  GetUserById = (id: number) => this.httpService.get(`user/${id}`);
  //forgot
  forgotpassword = (payload : any) => this.httpService.post('Auth/ForgotPassword', payload);
  //signup user
  signup = (payload : any) => this.httpService.post('User/SignupUser', payload);

}
